import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const inviewAnim = () => {
  function addInviewAnim(el, triggerClass, offset) {
    gsap.utils.toArray(el).forEach((el) => {
      ScrollTrigger.create({
        trigger: el,
        markers: false,
        start: "top " +  offset,
        toggleClass: {
          targets: el,
          className: triggerClass
        },
        once: true,
      });
    })
  }

  addInviewAnim('.inview', 'animate', '75%');
};

export default inviewAnim;
