import MicroModal from 'micromodal'

const customerQuotationEditBtn = () => {
  let quotations = document.querySelectorAll(
    '.is-customer.top .quotation-list__item'
  )
  const list = document.querySelector('.is-customer.top .quotation-list')

  if (quotations.length > 0) {
    const closeButton = document.querySelector('.js-modal-close'),
      modalDeleteButton = document.querySelector('.js-back-btn'),
      modalCopyDeleteButton = document.querySelector('.js-modal-close-copy'),
      modalCopy = document.querySelector('.modal-copy'),
      forms = document.querySelectorAll('.delete-form')

    closeButton.onclick = () => {
      MicroModal.close('confirm-modal')
      forms.forEach((el) => el.removeAttribute('data-delete'))
    }

    modalDeleteButton.addEventListener('click', () => {
      MicroModal.close('confirm-modal')
      const target = document.querySelector('.delete-form[data-delete]')
      target.submit()
    })

    // modalCopyDeleteButton.addEventListener('click', () => {
    //   MicroModal.close('confirm-modal-copy')
    // })

    quotations.forEach((el, i) => {
      const button = el.querySelector('.quotation-data__edit-btn')
      const edit = el.querySelector('.edit-list')
      const bg = el.querySelector('.edit-list-bg')
      const copyButton = el.querySelector('.edit-list__btn_copy')
      const deleteButton = el.querySelector('.edit-list__btn_delete')
      const deleteForm = deleteButton.closest('form')
      const pdfButton = el.querySelector('.edit-list__btn_pdf')

      const classRemove = () => {
        button.classList.remove('is-active')
        edit.classList.remove('is-active')
        bg.classList.remove('is-active')
      }

      button.onclick = () => {
        button.classList.toggle('is-active')
        edit.classList.toggle('is-active')
        bg.classList.toggle('is-active')
      }

      bg.onclick = () => classRemove()

      // copyButton.onclick = () => {
      //   classRemove()
      //   const copyEl = el.cloneNode(true)
      //   list.appendChild(copyEl)
      //   customerQuotationEditBtn()
      //   MicroModal.show('confirm-modal-copy')
      //   modalCopy.classList.add('is-show')

      //   setTimeout(() => {
      //     modalCopy.classList.remove('is-show')

      //     setTimeout(() => {
      //       MicroModal.close('confirm-modal-copy')
      //     }, 200)
      //   }, 1000)
      // }

      deleteButton.onclick = (e) => {
        e.preventDefault()
        MicroModal.show('confirm-modal')
        const target = e.target.closest('form')
        target.setAttribute('data-delete', '')
        classRemove()
      }

      pdfButton.onclick = () => {
        classRemove()
      }
    })
  }
}

export default customerQuotationEditBtn
