import { sync } from './groupSync'

const detailPrice = () => {
  const detail = document.querySelector(
    '.section-quotation-detail:not(.config, .other, .option)'
  )
  if (detail) {
    const quantity = detail.querySelector('#number'),
      quantity2 = detail.querySelector('#number2'),
      quantity2Parent = detail.querySelector('.detail-config__defi_number2'),
      height = detail.querySelector('#height'),
      heightText = detail.querySelector('#height-text'),
      group = detail.querySelector('#group'),
      // methodいずれ削除
      method = detail.querySelector('#method'),
      methodText = detail.querySelector('#method-text'),
      //
      dispose = detail.querySelector('#dispose'),
      trashList = detail.querySelector('.trash-list'),
      disposePrice = detail.querySelector('#dispose-price'),
      coef = detail.querySelector('#coefficient'),
      unit = detail.querySelector('#unit-price'),
      coef2 = detail.querySelector('#coefficient2'),
      unit2 = detail.querySelector('#unit-price2'),
      btn = detail.querySelector('.js-change-btn'),
      resetBtn = detail.querySelector('.js-reset-btn'),
      pesticide = detail.querySelector('#pesticide'),
      pesticideValue = Number(pesticide?.getAttribute('data-value')) - 1.0

    const price = detail.querySelector('.detail-total__price .price'),
      priceInput = detail.querySelector('.detail-total__price input')

    sync(group.value)

    let gCoef = coef.value
    let gUnit = Math.round(unit.value * gCoef)

    coef2.innerHTML = gCoef
    unit2.innerHTML = Number(gUnit).toLocaleString()

    const priceCalc = () => {
      const key = height.options[height.selectedIndex].innerText
      const q = (quantity2 && quantity2Parent.style.display !== 'none') ? quantity2.value : quantity.value;
      const d = dispose.checked
        ? trashList.querySelector(`li[data-key="${key}"]`).innerText
        : 0
      disposePrice.value = d

      coef.value = gCoef
      coef2.innerHTML = gCoef
      unit.value = gUnit
      unit2.innerHTML = Number(gUnit).toLocaleString()

      const p = (Number(gUnit) + Number(d) * gCoef) * q
      price.innerHTML = Math.round(Number(p)).toLocaleString()
      priceInput.setAttribute('value', Math.round(Number(p)))
      console.log(p)
    }

    const inputs = [quantity, quantity2, height, method, dispose]

    group.addEventListener('change', (e) => {
      sync(e.target.value)
      gUnit = height.value
      priceCalc()
    })

    height?.addEventListener('change', (e) => {
      heightText.value = height.options[height.selectedIndex].text
      gUnit = Math.round(e.target.value * gCoef)
      unit2.innerHTML = Number(gUnit).toLocaleString()
    })

    coef.addEventListener('change', (e) => {
      gCoef = e.target.value
      coef2.innerHTML = gCoef
      gUnit = Math.round(height.value * gCoef)
      unit.value = gUnit
      unit2.innerHTML = Number(gUnit).toLocaleString()
    })

    // 後ほど削除
    method?.addEventListener('change', (e) => {
      if (pesticide) {
        gCoef = pesticide.checked
          ? Number(e.target.value) + pesticideValue
          : e.target.value
      } else {
        gCoef = e.target.value
      }
      methodText.value = method.options[method.selectedIndex].text
    })

    inputs.forEach((input) => {
      input?.addEventListener('change', () => {
        priceCalc()
      })
    })

    btn.addEventListener('click', () => {
      if (!btn.classList.contains('is-active')) {
        gCoef = coef.value
        gUnit = unit.value

        coef2.innerHTML = gCoef
        unit2.innerHTML = Number(gUnit).toLocaleString()

        const key = height.options[height.selectedIndex].innerText
        const q = (quantity2 && quantity2Parent.style.display !== 'none') ? quantity2.value : quantity.value
        const d = dispose.checked
          ? trashList.querySelector(`li[data-key="${key}"]`).innerText
          : 0

        const p = (Number(gUnit) + Number(d) * gCoef) * q
        price.innerHTML = Math.round(Number(p)).toLocaleString()
        priceInput.setAttribute('value', Math.round(Number(p)))
      }
    })

    resetBtn.addEventListener('click', () => {
      unit.value = height.value
      unit.innerText = height.value
      gUnit = height.value
      coef.value = '1.0'
      coef2.innerHTML = '1.0'
      gCoef = 1.0
    })

    // 後ほど削除
    pesticide?.addEventListener('change', (e) => {
      pesticide.checked
        ? (gCoef = Number(gCoef) + pesticideValue)
        : (gCoef = Number(gCoef) - pesticideValue)
      priceCalc()
    })

    // 面積から料金を算出する場合
    const length = detail.querySelector('#length'),
      depth = detail.querySelector('#depth')

    if (length && depth) {
      quantity.value = Number(length.value) * Number(depth.value)
      ;[length, depth].forEach((el) => {
        el.addEventListener('change', (e) => {
          quantity.value = Number(length.value) * Number(depth.value)
          priceCalc()
        })
      })
    }

    priceCalc()
  }
}

export default detailPrice
