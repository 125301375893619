const quotationDetailLabel = () => {
  const labels = document.querySelectorAll(
    '.section-quotation-detail .label:not([class*="label_work"])'
  )

  const open = (label, input) => {
    label.classList.add('is-active')
    input.checked = true
  }

  const close = (label, input) => {
    label.classList.remove('is-active')
    input.checked = false
  }

  if (labels.length > 0) {
    labels.forEach((label) => {
      const input = label.querySelector('input')

      label.addEventListener('click', () => {
        label.classList.contains('is-active')
          ? close(label, input)
          : open(label, input)
      })
    })
  }
}

export default quotationDetailLabel
