import { quotationState } from './quotationValidate'

const quotationDetailConfig = () => {
  const button = document.querySelector(
    '.section-quotation-detail .js-change-btn'
  )

  if (button) {
    const edit = document.querySelector('.detail-config__root-param_edit')
    const text = document.querySelector('.detail-config__root-param_disable')
    const postButton = document.querySelector('.detail-total__btn')
    const resetButton = document.querySelector(
      '.section-quotation-detail .js-reset-btn'
    )
    const bodyInputs = document.querySelectorAll('.detail-config__body input')
    const bodySelects = document.querySelectorAll('.detail-config__body select')
    const disposeBtn = document.querySelector('label[for="dispose"]')
    let state = false

    const open = () => {
      edit.classList.add('is-active')
      text.classList.add('is-hide')
      button.classList.add('is-active')
      resetButton.classList.add('is-active')
      if (quotationState) {
        postButton.setAttribute('disabled', true)
        postButton.classList.add('inactive')
      }
      bodyInputs.forEach((input) => {
        input?.setAttribute('disabled', true)
      })
      bodySelects.forEach((select) => {
        select?.setAttribute('disabled', true)
      })
      disposeBtn?.setAttribute('disabled', true)
    }

    const close = () => {
      edit.classList.remove('is-active')
      text.classList.remove('is-hide')
      button.classList.remove('is-active')
      resetButton.classList.remove('is-active')
      if (quotationState) {
        postButton.removeAttribute('disabled')
        postButton.classList.remove('inactive')
      }
      bodyInputs.forEach((input) => {
        input?.removeAttribute('disabled')
      })
      bodySelects.forEach((select) => {
        select?.removeAttribute('disabled')
      })
      disposeBtn?.removeAttribute('disabled')
    }

    button.addEventListener('click', () => {
      state = !state
      state ? open() : close()
    })
  }
}

export default quotationDetailConfig
