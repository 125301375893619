import MicroModal from 'micromodal'

const imageUpload = () => {
  const form = document.getElementById('image-upload')
  if (form) {
    const input = document.getElementById('image')
    const error = document.querySelector('.image-upload__error')

    input.addEventListener('change', (e) => {
      error.innerText = ''
      const file = e.target.files[0]
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        form.submit()
      } else {
        error.innerText = '画像ファイルではありません。'
      }
    })
  }

  const imageList = document.querySelector('.image-upload__list')
  if (imageList) {
    const images = imageList.querySelectorAll('.image-list__image')
    const download = document.getElementById('download-button')
    const deleteForm = document.getElementById('image-delete')
    const deleteButton = document.getElementById('image-delete-button')
    const modalCloseButton = document.querySelector(
      '#image-confirm-modal .js-modal-close'
    )
    const modalDeleteButton = document.querySelector(
      '#image-confirm-modal .js-delete-btn'
    )
    const inputId = document.querySelector('input[name="image-id"]')

    images.forEach((img) => {
      img.addEventListener('click', () => {
        images.forEach((img) => img.classList.remove('is-active'))

        img.classList.add('is-active')
        download.classList.remove('is-disabled')
        download.href = img.src
        inputId.value = img.getAttribute('data-id')
        deleteButton.classList.remove('is-disabled')
      })
    })

    deleteButton.addEventListener('click', (e) => {
      e.preventDefault()
      MicroModal.show('image-confirm-modal')
    })

    modalCloseButton.addEventListener('click', () =>
      MicroModal.close('image-confirm-modal')
    )
    modalDeleteButton.addEventListener('click', () => {
      MicroModal.close('image-confirm-modal')
      deleteForm.submit()
    })
  }
}

export default imageUpload
