const customerSearch = () => {
  const input = document.querySelector('.customer-index__search input')

  if (input) {
    const list = document.querySelector('.customer-result-list')
    const item = list.querySelectorAll('.customer-result-item')

    window.addEventListener('pageshow', function (event) {
      var entries = performance.getEntriesByType('navigation')
      entries.forEach(function (entry) {
        if (entry.type == 'back_forward') {
          if (input.value) {
            item.forEach((item) => {
              item.style.display = 'block'
            })

            const targets = list.querySelectorAll(
              '.customer-result-item:not([data-search*=' + input.value + '])'
            )
            targets.forEach((target) => {
              target.style.display = 'none'
            })
          }
        }
      })
    })

    input.addEventListener('change', (e) => {
      item.forEach((item) => {
        item.style.display = 'block'
      })

      const targets = list.querySelectorAll(
        '.customer-result-item:not([data-search*=' + e.target.value + '])'
      )
      targets.forEach((target) => {
        target.style.display = 'none'
      })
    })
  }
}

export default customerSearch
