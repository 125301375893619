import MicroModal from 'micromodal'

const quotationDetailModal = () => {
  const buttons = document.querySelectorAll(
    '.section-quotation-detail .label_work'
  )

  if (buttons.length > 0) {
    const closeButton = document.querySelector('.js-modal-close'),
      backButton = document.querySelector('.js-back-btn')

    buttons.forEach((button) => {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        MicroModal.show('confirm-modal')

        backButton.onclick = () => {
          location.href = e.target.href
        }
      })

      closeButton.onclick = () => {
        MicroModal.close('confirm-modal')
      }
    })
  }
}

export default quotationDetailModal
