import MicroModal from 'micromodal'

const customerDeleteButton = () => {
  const form = document.getElementById('customer-delete')
  if (form) {
    const button = document.getElementById('customer-delete-button')
    const closeButton = document.querySelector('.js-delete-modal-close')
    const deleteButton = document.querySelector('.js-delete-back-btn')

    button.addEventListener('click', (e) => {
      e.preventDefault()
      MicroModal.show('customer-delete-modal')
    })

    closeButton.addEventListener('click', () => {
      MicroModal.close('customer-delete-modal')
    })

    deleteButton.addEventListener('click', () => {
      form.submit()
    })
  }
}

export default customerDeleteButton
