import customerResultControll from './modules/customerResultControll'
import customerSwitch from './modules/customerSwitch'
import customerSearchAddress from './modules/customerSearchAddress'
import customerQuotationEditBtn from './modules/customerQuotationEditBtn'
import quotationItemDeleteButton from './modules/quotationItemDeletButton'
import quotationDetailLabel from './modules/qotationDetailLabel'
import quotationDetailconfig from './modules/quotationDetailConfig'
import quotationDetailModal from './modules/quotationDetailModal'
import login from './modules/login'
import globalNav from './modules/globalNavi'
import primaryButton from './modules/primary-btn'
import customerSearch from './modules/customerSearch'
// import './customer-add'
import detailPrice from './modules/detailPrice'
import modalMessage from './modules/modal-message'
import detailConfigCalc from './modules/detailConfigCalc'
import inviewAnim from './modules/inview'
import optionCalc from './modules/optionCalc'
import loadingAnim from './modules/loading'
import confirmModal from './modules/confirmModal'
import formEnterCancel from './modules/formEnterCancel'
import quotationOptionValidation from './modules/quotationOptionValidation'
import customerValidation from './modules/customerValidation'
import imageUpload from './modules/imageUpload'
import quotationValidate from './modules/quotationValidate'
import customerDeleteButton from './modules/customerDeleteButton'
import quotationAreaSwitch from './modules/quotationAreaSwitch'
import product from './modules/product'
import expenses from './modules/expenses'
import expensesValidation from './modules/expensesValidation'
import discountExtra from './modules/discountExtra'
import discountExtraValidation from './modules/discountExtraValidation'

window.addEventListener('DOMContentLoaded', function () {
  loadingAnim()
})

window.addEventListener('load', () => {
  modalMessage()
  customerResultControll()
  customerSwitch()
  // customerSearchAddress()
  customerQuotationEditBtn()
  quotationItemDeleteButton()
  quotationDetailLabel()
  quotationDetailconfig()
  quotationDetailModal()
  login()
  globalNav()
  customerSearch()
  primaryButton()
  quotationAreaSwitch()
  detailPrice()
  // setFormValidate()
  detailConfigCalc()
  inviewAnim()
  confirmModal()
  formEnterCancel()
  customerValidation()
  imageUpload()
  quotationValidate()
  customerDeleteButton()
  product()
  expensesValidation()
  expenses()
  discountExtraValidation()
  discountExtra()
})
