const quotationAreaSwitch = () => {
  const number = document.querySelector('.detail-config__defi_number2')
  if (number) {
    const numberInput = number.querySelector('#number2')
    let select = document.querySelector('select[id="number"]')

    if (!select) select = document.querySelector('select[id="height"]')

    const areaSwitch = () => {
      if (select.children[select.children.length - 1].selected) {
        number.style.display = 'flex'
      } else {
        number.style.display = 'none'
        numberInput.value = ''
      }
    }

    areaSwitch()

    select.addEventListener('change', () => {
      areaSwitch()
    })
  }
}

export default quotationAreaSwitch
