import { numberSchema, requiredSchema } from './validationSchema'

const product = () => {
  const target = document.querySelector('.section-quotation-detail.product')
  if (target) {
    const productPrice = document.getElementById('product-price'),
      productQuantity = document.getElementById('product-quantity'),
      productTotal = document.getElementById('product-totalprice'),
      productContent = document.getElementById('product-content'),
      productUnit = document.getElementById('product-unit'),
      totalPrice = document.getElementById('total-price'),
      totalPriceText = document.getElementById('total-price-text'),
      submitButton = document.querySelector('.js-submit-btn')

    const numberInputs = [productPrice, productQuantity],
      textInputs = [productContent, productUnit]

    // ボタンのアクティブ、非アクティブ
    let results = []
    const pushSuccess = (el, schema) => {
      const result = schema.safeParse(el.value).success
      results.push(result)
    }

    const toggleButton = () => {
      results = []

      textInputs.forEach((input) => {
        pushSuccess(input, requiredSchema)
      })

      numberInputs.forEach((input) => {
        pushSuccess(input, numberSchema)
      })

      const flag = results.every((bool) => bool)

      if (flag) {
        submitButton.disabled = false
        submitButton.classList.remove('inactive')
      } else {
        submitButton.disabled = true
        submitButton.classList.add('inactive')
      }
    }

    toggleButton()

    const calc = () => {
      const value = productPrice.value * productQuantity.value
      productTotal.value = value
      totalPrice.value = value
      totalPriceText.innerText = value.toLocaleString()
    }

    textInputs.forEach((input) => {
      input.addEventListener('change', () => {
        validation(input, requiredSchema)
        toggleButton()
      })
    })

    numberInputs.forEach((input) => {
      input.addEventListener('change', () => {
        calc()
        validation(input, numberSchema)
        toggleButton()
      })
    })
  }
}

/**
 * エラーメッセージを挿入
 * @param {Element} el - 主にinputなど
 * @param {error.issues} issues
 */
export const errorMessage = (el, issues) => {
  el.classList.add('is-invalid')
  issues.forEach((e) => {
    el.insertAdjacentHTML(
      'afterend',
      `<div class="invalid-feedback">${e.message}</div>`
    )
  })
}

/**
 * エラーメッセージを削除
 * @param {Element} el - 主にinputなど
 */
export const removeError = (el) => {
  el.classList.remove('is-invalid')
  const parent = el.parentElement
  const errors = parent.querySelectorAll(':scope > .invalid-feedback')
  errors.forEach((error) => {
    error.remove()
  })
}

export const priceCalc = (check, price, quantity, total) => {
  total.setAttribute('readonly', true)
  total.value = Number(price.value) * Number(quantity.value)
  check.addEventListener('change', () => totalCalc())
  ;[price, quantity].forEach((input) => {
    input.addEventListener('change', () => {
      total.value = Number(price.value) * Number(quantity.value)
      totalCalc()
    })
  })
}

export const validation = (el, schema) => {
  removeError(el)

  try {
    schema.parse(el.value)
  } catch (error) {
    errorMessage(el, error.issues)
  }
}

export default product
