import { toggleButton } from './expensesValidation'

const expenses = () => {
  const target = document.querySelector('.section-quotation-detail.expenses')
  if (target) {
    const tempoCheck = document.getElementById('temporarywork-check'),
      tempoPrice = document.getElementById('temporarywork-price'),
      tempoQuantity = document.getElementById('temporarywork-quantity'),
      tempoTotal = document.getElementById('temporarywork-totalprice'),
      guideCheck = document.getElementById('guide-check'),
      guideQuantity = document.getElementById('guide-people'),
      guidePrice = document.getElementById('guide-price'),
      guideTotal = document.getElementById('guide-totalprice'),
      vehicleCheck = document.getElementById('special-vehicle-check'),
      vehiclePrice = document.getElementById('special-vehicle-price'),
      vehicleQuantity = document.getElementById('special-vehicle-quantity'),
      vehicleTotal = document.getElementById('special-vehicle-totalprice'),
      disposalCheck = document.getElementById('disposal-check'),
      disposalPrice = document.getElementById('disposal-price'),
      disposalQuantity = document.getElementById('disposal-quantity'),
      disposalTotal = document.getElementById('disposal-totalprice'),
      travelCheck = document.getElementById('travel-cost-check'),
      travelContent = document.getElementById('travel-cost-distance'),
      travelPrice = document.getElementById('travel-cost-price'),
      travelQuantity = document.getElementById('travel-cost-quantity'),
      travelTotal = document.getElementById('travel-cost-totalprice'),
      tollRoadCheck = document.getElementById('toll-road-check'),
      roadPrice = document.getElementById('toll-road-price'),
      roadQuantity = document.getElementById('toll-road-quantity'),
      roadTotal = document.getElementById('toll-road-totalprice'),
      otherCheck = document.getElementById('other-check'),
      otherPrice = document.getElementById('other-price'),
      otherQuantity = document.getElementById('other-quantity'),
      otherTotal = document.getElementById('other-totalprice'),
      roadCheck = document.getElementById('road-check'),
      permissionTotal = document.getElementById('road-price')

    const totals = [
      tempoTotal,
      guideTotal,
      vehicleTotal,
      disposalTotal,
      travelTotal,
      roadTotal,
      otherTotal,
      permissionTotal,
    ]

    const totalPrice = document.getElementById('total-price')
    const totalPriceText = document.getElementById('total-price-text')

    const totalCalc = () => {
      const totalsValue = totals.map((total) => {
        return total.disabled ? 0 : Number(total.value)
      })
      const value = totalsValue.reduce((prev, current) => prev + current, 0)
      totalPrice.value = value
      totalPriceText.innerText = value.toLocaleString()

      toggleButton()
    }

    const priceCalc = (check, price, quantity, total) => {
      total.setAttribute('readonly', true)
      total.value = Number(price.value) * Number(quantity.value)
      check.addEventListener('change', () => totalCalc())
      ;[price, quantity].forEach((input) => {
        input.addEventListener('change', () => {
          total.value = Number(price.value) * Number(quantity.value)
          totalCalc()
        })
      })
    }

    const priceCalc2 = (check, unit, price, quantity, total) => {
      total.setAttribute('readonly', true)
      total.value =
        Number(unit.value) * Number(price.value) * Number(quantity.value)
      check.addEventListener('change', () => totalCalc())
      ;[unit, price, quantity].forEach((input) => {
        input.addEventListener('change', () => {
          total.value =
            Number(unit.value) * Number(price.value) * Number(quantity.value)
          totalCalc()
        })
      })
    }

    ;[roadCheck, permissionTotal].forEach((input) => {
      input.addEventListener('change', () => {
        totalCalc()
      })
    })

    priceCalc(tempoCheck, tempoPrice, tempoQuantity, tempoTotal)
    priceCalc(guideCheck, guidePrice, guideQuantity, guideTotal)
    priceCalc(vehicleCheck, vehiclePrice, vehicleQuantity, vehicleTotal)
    priceCalc(disposalCheck, disposalPrice, disposalQuantity, disposalTotal)
    priceCalc2(
      travelCheck,
      travelContent,
      travelPrice,
      travelQuantity,
      travelTotal
    )
    priceCalc(tollRoadCheck, roadPrice, roadQuantity, roadTotal)
    priceCalc(otherCheck, otherPrice, otherQuantity, otherTotal)

    totalCalc()
  }
}

export default expenses
