import {
  addressSchema,
  apartSchema,
  emailSchema,
  nameSchema,
  requiredSchema,
  tel1Schema,
  tel1Schema2,
  tel2Schema,
  tel2Schema2,
  tel3Schema,
  tel3Schema2,
  zipSchema,
} from './validationSchema'

const customerValidation = () => {
  const customerForm = document.querySelector(
    '.is-customer:not(.top, .select) .section-customer'
  )
  if (customerForm) {
    const nameD = document.querySelector('input[name="name-d"]'),
      nameKana = document.querySelector('input[name="name-kana"]'),
      company = document.querySelector('input[name="company"]'),
      zip = document.querySelector('input[name="zip"]'),
      pref = document.querySelector('select[name="pref"]'),
      city = document.querySelector('input[name="city"]'),
      apart = document.querySelector('input[name="apart"]'),
      tel1 = document.querySelector('input[name="tel1"]'),
      tel2 = document.querySelector('input[name="tel2"]'),
      tel3 = document.querySelector('input[name="tel3"]'),
      tel2_1 = document.querySelector('input[name="tel2-1"]'),
      tel2_2 = document.querySelector('input[name="tel2-2"]'),
      tel2_3 = document.querySelector('input[name="tel2-3"]'),
      email = document.querySelector('input[name="email"]'),
      types = document.querySelectorAll('input[name="customer-type"]'),
      submitButton = document.querySelector('.js-submit-btn')

    const requireds = [pref]
    const targets = [
      nameD,
      nameKana,
      company,
      zip,
      pref,
      city,
      apart,
      tel1,
      tel2,
      tel3,
      tel2_1,
      tel2_2,
      tel2_3,
      email,
      ...types,
    ]

    // チェンジ時のバリデーション処理
    requireds.forEach((el) => {
      el.addEventListener('change', (event) => {
        removeError(el)

        try {
          requiredSchema.parse(event.target.value)
        } catch (error) {
          errorMessage(el, error.issues)
        }
      })
    })

    nameValidation(nameD, nameSchema)
    nameValidation(nameKana, nameSchema)
    validation(company, requiredSchema)
    validation(zip, zipSchema)
    validation(city, addressSchema)
    validation(apart, apartSchema)
    validation(tel1, tel1Schema)
    validation(tel2, tel2Schema)
    validation(tel3, tel3Schema)
    validation(tel2_1, tel1Schema2)
    validation(tel2_2, tel2Schema2)
    validation(tel2_3, tel3Schema2)
    validation(email, emailSchema)

    // ボタンのアクティブ、非アクティブ
    let results = []
    const pushSuccess = (el, schema) => {
      const result = schema.safeParse(el.value).success
      results.push(result)
    }

    const toggleButton = () => {
      results = []

      requireds.forEach((el) => pushSuccess(el, requiredSchema))
      pushSuccess(nameD, nameSchema)
      pushSuccess(nameKana, nameSchema)
      company.disabled || pushSuccess(company, requiredSchema)
      pushSuccess(zip, zipSchema)
      pushSuccess(city, addressSchema)
      pushSuccess(apart, apartSchema)
      pushSuccess(tel1, tel1Schema)
      pushSuccess(tel2, tel2Schema)
      pushSuccess(tel3, tel3Schema)
      pushSuccess(tel2_1, tel1Schema2)
      pushSuccess(tel2_2, tel2Schema2)
      pushSuccess(tel2_3, tel3Schema2)
      pushSuccess(email, emailSchema)

      const flag = results.every((bool) => bool)

      if (flag) {
        submitButton.disabled = false
        submitButton.classList.remove('inactive')
      } else {
        submitButton.disabled = true
        submitButton.classList.add('inactive')
      }
    }

    toggleButton()

    targets.forEach((el) => {
      el.addEventListener('change', () => toggleButton())
    })

    // 住所自動取得処理
    const triggers = document.querySelectorAll('.js-search-address')

    for (const trigger of triggers) {
      trigger.addEventListener('click', function () {
        const api = 'https://zipcloud.ibsnet.co.jp/api/search?zipcode='
        const param = zip.value
        const url = api + param

        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            pref.value = data.results[0].address1
            removeError(pref)

            try {
              requiredSchema.parse(pref.value)
            } catch (error) {
              errorMessage(pref, error.issues)
            }

            city.value = data.results[0].address2 + data.results[0].address3
            removeError(city)

            try {
              addressSchema.parse(city.value)
            } catch (error) {
              errorMessage(city, error.issues)
            }

            toggleButton()
          })
      })
    }
  }
}

/**
 * エラーメッセージを削除
 * @param {Element} el - 主にinputなど
 */
const removeError = (el) => {
  el.classList.remove('is-invalid')
  const parent = el.parentElement
  const errors = parent.querySelectorAll('.error')
  errors.forEach((error) => {
    error.remove()
  })
}
/**
 * エラーメッセージを挿入
 * @param {Element} el - 主にinputなど
 * @param {error.issues} issues
 */
const errorMessage = (el, issues) => {
  el.classList.add('is-invalid')
  issues.forEach((e) => {
    el.insertAdjacentHTML('afterend', `<div class="error">${e.message}</div>`)
  })
}

const validation = (el, schema) => {
  el.addEventListener('change', (event) => {
    removeError(el)

    try {
      schema.parse(event.target.value)
    } catch (error) {
      errorMessage(el, error.issues)
    }
  })
}

const nameValidation = (el, schema) => {
  el.addEventListener('change', (event) => {
    removeError(el)

    const value = event.target.value.replace('　', ' ')
    el.value = value
    try {
      schema.parse(el.value)
    } catch (error) {
      errorMessage(el, error.issues)
    }
  })
}

export default customerValidation
