import MicroModal from 'micromodal'

const confirmModal = () => {
  const customerForm = document.querySelector('form[name="customerForm"]')
  const detailForm = document.querySelector('.section-quotation-detail form')

  if (customerForm || detailForm) {
    const inputs = document.querySelectorAll('input')
    const selects = document.querySelectorAll('select')
    const textareas = document.querySelectorAll('textarea')
    const labelButtons = document.querySelectorAll('.add-label__item button')
    const links = document.querySelectorAll('a[href]')
    const closeBtn = document.querySelector('.js-modal-close')
    const transitionBtn = document.querySelector('.js-transition-btn')

    closeBtn.addEventListener('click', () => MicroModal.close('confirm-modal'))

    let flag = false

    ;[...inputs, ...selects, ...textareas].forEach((el) => {
      el?.addEventListener('change', () => {
        if (!flag) {
          flag = true
          history.pushState(null, null, null)
        }
      })
    })

    labelButtons.forEach((el) => {
      el?.addEventListener('click', () => {
        if (!flag) {
          flag = true
          history.pushState(null, null, null)
        }
      })
    })

    window.addEventListener('popstate', () => {
      if (flag) {
        history.pushState(null, null, null)

        MicroModal.show('confirm-modal')
        transitionBtn.onclick = () => {
          history.go(-2)
        }
      }
    })

    links.forEach((el, i) => {
      el?.addEventListener('click', (e) => {
        if (flag) {
          e.preventDefault()

          MicroModal.show('confirm-modal')
          transitionBtn.onclick = () => {
            location.href = links[i].href
          }
        }
      })
    })

    // window.addEventListener('beforeunload', (e) => {
    //   if (flag) {
    //     e.preventDefault()
    //     e.returnValue = ''
    //   }
    // })
  }
}

export default confirmModal
