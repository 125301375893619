let group
if (localStorage.niwamitsu_quotation) {
  group = JSON.parse(localStorage.niwamitsu_quotation)
}
const height = document.getElementById('height')
const heightText = document.getElementById('height-text')
const number = document.getElementById('number')
const unit = document.querySelector('#unit-price')
const unitText = document.querySelector('#unit-price2')
const depth = document.getElementById('depth')
const length = document.getElementById('length')

const heightInitialize = height?.value
const heightTextInitialize = heightText?.innerText
const numberInitialize = number?.value
const unitInitialize = unit?.value
const unitTextInitialize = unitText?.innerText
const depthInitialize = depth?.value
const lengthInitialize = length?.value

const hightrees = [
  'pruning-stumpage',
  'chemical-hightree',
  'fertilization-hightree',
]
const hedges = ['pruning-hedge', 'chemical-hedge', 'fertilization-hedge']
const gardenings = [
  'pruning-gardening',
  'chemical-gardening',
  'fertilization-gardening',
]
const stumps = ['pruning-stump', 'chemical-stump', 'fertilization-stump']

const reset = () => {
  number.value = numberInitialize

  if (
    hightrees.includes(slug) ||
    stumps.includes(slug) ||
    hedges.includes(slug)
  ) {
    height.value = heightInitialize
    heightText.innerText = heightTextInitialize
    unit.value = unitInitialize
    unitText.innerHTML = unitTextInitialize
  } else if (gardenings.includes(slug)) {
    depth.value = depthInitialize
    length.value = lengthInitialize
  }
}

export const sync = (index) => {
  let targets = group[index]
  if (targets) {
    targets = targets.filter((target) => {
      if (hightrees.includes(slug)) {
        return (
          target.works_table === 'pruning_stumpage' ||
          target.works_table === 'chemical_hightree' ||
          target.works_table === 'fertilization_hightree'
        )
      } else if (hedges.includes(slug)) {
        return (
          target.works_table === 'pruning_hedge' ||
          target.works_table === 'chemical_hedge' ||
          target.works_table === 'fertilization_hedge'
        )
      } else if (gardenings.includes(slug)) {
        return (
          target.works_table === 'pruning_gardening' ||
          target.works_table === 'chemical_gardening' ||
          target.works_table === 'fertilization_gardening'
        )
      } else if (stumps.includes(slug)) {
        return (
          target.works_table === 'pruning_stump' ||
          target.works_table === 'chemical_stump' ||
          target.works_table === 'fertilization_stump'
        )
      }
    })
    if (targets.length > 0) {
      targets.sort((a, b) => {
        return a.update_at < b.update_at ? 1 : -1
      })

      const target = targets[0]
      number.value = target['quantity']

      if (
        hightrees.includes(slug) ||
        stumps.includes(slug) ||
        hedges.includes(slug)
      ) {
        const heightOption = [...height.options].filter((option) => {
          return option.innerText === target['size']
        })
        height.options[heightOption[0].index].selected = true
        heightText.innerText = height.options[height.selectedIndex].text
        heightText.value = height.options[height.selectedIndex].text
        unit.value = height.value
        unitText.innerHTML = Number(height.value).toLocaleString()
      } else if (gardenings.includes(slug)) {
        depth.value = target['depth']
        length.value = target['length']
      }
    } else {
      reset()
    }
  } else {
    reset()
  }
}
