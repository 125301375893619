import { z } from 'zod'

// スキーマの設定
export const requiredSchema = z
  .string('必須項目です。')
  .nonempty('必須項目です。')
export const numberSchema = z
  .string()
  .regex(/^[0-9]+$|^$|^-[0-9]$/, '半角数字で入力してください。')
  .regex(/^(?!^0|-(?:0|[1-9]\d*)$)/, '０以下の数値は入力できません。')
  .nonempty('必須項目です。')
export const numberRegexSchema = z
  .string()
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
  .regex(/^(?!^0|-(?:0|[1-9]\d*)$)/, '０以下の数値は入力できません。')
export const nameSchema = z
  .string()
  .nonempty('必須項目です。')
  .max(60, '６０文字以内で入力してください。')
export const addressSchema = z
  .string()
  .nonempty('必須項目です。')
  .max(65, '６５文字以内で入力してください。')
export const apartSchema = z
  .string()
  .max(65, '６５文字以内で入力してください。')
export const zipSchema = z
  .string({
    required_error: '必須項目です。',
    invalid_type_error: '半角数字７桁で入力してください。',
  })
  .nonempty('必須項目です。')
  .regex(/^[0-9]{7}$|^$/, '半角数字７桁で入力してください。')
export const tel1Schema = z
  .string()
  .nonempty('必須項目です。')
  .min(2, '入力文字数が少ないです。')
  .max(5, '入力文字数が多いです。')
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
export const tel2Schema = z
  .string()
  .nonempty('必須項目です。')
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
  .min(1, '入力文字数が少ないです。')
  .max(4, '入力文字数が多いです。')
export const tel3Schema = z
  .string()
  .nonempty('必須項目です。')
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
  .min(4, '入力文字数が少ないです。')
  .max(4, '入力文字数が多いです。')
export const tel1Schema2 = z
  .string()
  .max(5, '入力文字数が多いです。')
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
export const tel2Schema2 = z
  .string()
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
  .max(4, '入力文字数が多いです。')
export const tel3Schema2 = z
  .string()
  .regex(/^[0-9]+$|^$/, '半角数字で入力してください。')
  .max(4, '入力文字数が多いです。')
export const emailSchema = z
  .string()
  .regex(
    /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$|^$/,
    '正しい値を入力してください。'
  )
  .max(256, '２５６文字以内で入力してください。')
export const unitSchema = z
  .string()
  .regex(/^[0-9]+$|^$|^-[0-9]$/, '半角数字で入力してください。')
  .regex(/^(?!^0|-(?:0|[1-9]\d*)$)/, '０以下の数値は入力できません。')
  .max(7, '入力桁数が多いです。')
  .nonempty('必須項目です。')
export const intensitySchema = z
  .string()
  .regex(/^[0-9]+$|^$|^-[0-9]$/, '半角数字で入力してください。')
  .regex(/^(?!^0|-(?:0|[1-9]\d*)$)/, '０以下の数値は入力できません。')
  .max(3, '入力桁数が多いです。')
  .nonempty('必須項目です。')
