import MicroModal from 'micromodal'

const modalMessage = () => {
  const modalMessage = document.querySelector('#modal-message')
  const message = sessionStorage.getItem('message')

  if (message && modalMessage) {
    const text = modalMessage.querySelector('.confirm__text')
    const closeButton = modalMessage.querySelector('.js-modal-close-message')
    text.innerText = message
    sessionStorage.removeItem('message')
    MicroModal.show('modal-message')
    modalMessage.classList.add('is-show')

    setTimeout(() => {
      modalMessage.classList.remove('is-show')

      setTimeout(() => {
        MicroModal.close('modal-message')
      }, 200)
    }, 1500)

    closeButton.addEventListener('click', () => {
      MicroModal.close('modal-message')
    })
  }
}

export default modalMessage
