const formEnterCancel = () => {
  document.querySelectorAll('form').forEach((form) => {
    form?.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
      }
    })
  })
}

export default formEnterCancel
