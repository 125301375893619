import { toggleButton } from './discountExtraValidation'

const discountExtra = () => {
  const target = document.querySelector(
    '.section-quotation-detail.discount-extra'
  )
  if (target) {
    // 割引金額の計算
    let discountPrice = 0,
      discountPercent = 0,
      extraPrice = 0,
      extraPercent = 0
    const totalPriceText = document.getElementById('total-price-text')

    const totalCalc = () => {
      const totalPrice =
        constructionPrice +
        productPrice +
        expensesPrice -
        Math.round(
          ((constructionPrice + productPrice) * discountPercent) / 100
        ) -
        discountPrice +
        Math.round((constructionPrice * extraPercent) / 100) +
        extraPrice
      totalPriceText.innerText = Math.round(totalPrice).toLocaleString()
    }

    const memberPrice = document.getElementById('member-price'),
      campaignPrice = document.getElementById('campaign-price'),
      industryPrice = document.getElementById('industry-price'),
      discountPriceText = document.querySelector(
        '.detail-config__discount-price .num'
      )
    const discountPriceInputs = [memberPrice, campaignPrice, industryPrice]

    const discountPriceCalc = () => {
      const inputsValue = discountPriceInputs.map((input) =>
        input.disabled ? 0 : Number(input.value)
      )
      const value = inputsValue.reduce((prev, current) => prev + current, 0)
      discountPrice = value
      discountPriceText.innerText = value.toLocaleString()

      toggleButton()
    }
    discountPriceCalc()

    discountPriceInputs.forEach((input) => {
      input.addEventListener('change', () => {
        discountPriceCalc()
        totalCalc()
      })
    })

    // 割引率の計算
    const memberPercent = document.getElementById('member-percent'),
      campaignPercent = document.getElementById('campaign-percent'),
      discountPercentText = document.querySelector(
        '.detail-config__discount-percent .num'
      )

    const discountPercentInputs = [memberPercent, campaignPercent]
    const discountPercentCalc = () => {
      const inputsValue = discountPercentInputs.map((input) =>
        input.disabled ? 0 : Number(input.value)
      )
      const value = inputsValue.reduce((prev, current) => prev + current, 0)
      discountPercent = value
      discountPercentText.innerText = value

      toggleButton()
    }
    discountPercentCalc()

    discountPercentInputs.forEach((input) => {
      input.addEventListener('change', () => {
        discountPercentCalc()
        totalCalc()
      })
    })

    const premiumPrice = document.getElementById('premium-price'),
      premiumPercent = document.getElementById('premium-percent'),
      extraPriceText = document.querySelector(
        '.detail-config__extra-price .num'
      ),
      extraPercentText = document.querySelector(
        '.detail-config__extra-percent .num'
      )

    const extraPriceCalc = () => {
      const value = Number(premiumPrice.value)
      extraPrice = value
      extraPriceText.innerText = value.toLocaleString()
      toggleButton()
    }
    extraPriceCalc()

    const extraPercentCalc = () => {
      const value = Number(premiumPercent.value)
      extraPercent = value
      extraPercentText.innerText = value
      toggleButton()
    }
    extraPercentCalc()

    premiumPercent.addEventListener('change', () => {
      extraPercentCalc()
      totalCalc()
    })

    premiumPrice.addEventListener('change', () => {
      extraPriceCalc()
      totalCalc()
    })

    const campaignCheck = document.getElementById('campaign-check'),
      memberCheck = document.getElementById('member-check'),
      industryPriceCheck = document.getElementById('industry-price-check'),
      premiumCheck = document.getElementById('premium-check')

    ;[campaignCheck, memberCheck, industryPriceCheck].forEach((input) => {
      input.addEventListener('change', () => {
        discountPriceCalc()
        discountPercentCalc()
        totalCalc()
      })
    })

    premiumCheck.addEventListener('change', () => {
      extraPriceCalc()
      extraPercentCalc()
      totalCalc()
    })
  }
}

export default discountExtra
