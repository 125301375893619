const login = () => {
  function setPasswordBtn() {
    const btn = document.querySelector('.js-pass-visible');

    if(btn !== null) {
      btn.addEventListener('click', function() {
        this.classList.toggle("invisible");
  
        const input = this.previousElementSibling;
        if(this.classList.contains('invisible')) {
          input.type = 'text';
        } else {
          input.type = 'password';
        }
      })
    }
  }

  function setValidate() {
    const form = document.querySelector('.js-login-form');
    let btn;
    let inputs;
    if(form !== null) {
      btn = form.querySelector('.js-login-btn');
      inputs = form.querySelectorAll('.login__field');
    }
    
    if(btn !== null && btn !== undefined) {
      btn.addEventListener('click', function (e) {
        e.preventDefault();
        let count = 0;

        for(const input of inputs) {
          if(input.value == '' || input.value == null) {
            form.classList.remove('is-failed')
            form.classList.add('is-error')
            count++;
          }
        }

        if(count === 0) {
          form.submit();
        }
      })
    }
  }

  setPasswordBtn();
  setValidate();
};

export default login;
