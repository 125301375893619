import { intensitySchema, unitSchema } from './validationSchema'

export let quotationState = false

const quotationValidate = () => {
  const quotation = document.querySelector(
    '.section-quotation-detail:not(.option)'
  )
  if (quotation) {
    const intensity = document.querySelector(
        'input[name="number"]:not([type="hidden"])'
      ),
      unitPrice = document.querySelector('input[name="unit-price"]'),
      coefficient = document.querySelector('select[name="coefficient"]'),
      configButton = document.querySelector('.js-change-btn'),
      resetButton = document.querySelector('.js-reset-btn'),
      submitButton = document.querySelector('.detail-total__btn')

    // configのバリデーション
    const unitPriceValidation = () => {
      const result = validation(unitPrice, unitSchema)
      result
        ? configButton.classList.remove('inactive')
        : configButton.classList.add('inactive')
    }
    unitPrice?.addEventListener('change', () => unitPriceValidation())
    resetButton.addEventListener('click', () => unitPriceValidation())
    coefficient.addEventListener('change', () => unitPriceValidation())

    // 全体のバリデーション
    intensity?.addEventListener('change', () => {
      validation(intensity, intensitySchema)
    })

    // ボタンのアクティブ、非アクティブ
    let results = []
    const pushSuccess = (el, schema) => {
      const result = schema.safeParse(el.value).success
      results.push(result)
    }

    const toggleButton = () => {
      results = []

      intensity && pushSuccess(intensity, intensitySchema)

      quotationState = results.every((bool) => bool)

      if (quotationState) {
        submitButton.disabled = false
        submitButton.classList.remove('inactive')
      } else {
        submitButton.disabled = true
        submitButton.classList.add('inactive')
      }
    }

    toggleButton()
    ;[intensity].forEach((el) => {
      el?.addEventListener('change', () => toggleButton())
    })
  }
}

/**
 * エラーメッセージを挿入
 * @param {Element} el - 主にinputなど
 * @param {error.issues} issues
 */
const errorMessage = (el, issues) => {
  el.classList.add('is-invalid')
  const parent = el.parentElement
  issues.forEach((e) => {
    parent.insertAdjacentHTML(
      'beforeend',
      `<div class="invalid-feedback">${e.message}</div>`
    )
  })
}

/**
 * エラーメッセージを削除
 * @param {Element} el - 主にinputなど
 */
const removeError = (el) => {
  el.classList.remove('is-invalid')
  const parent = el.parentElement
  const errors = parent.querySelectorAll(':scope > .invalid-feedback')
  errors.forEach((error) => {
    error.remove()
  })
}

export const validation = (el, schema) => {
  removeError(el)

  try {
    schema.parse(el.value)
    return true
  } catch (error) {
    errorMessage(el, error.issues)
    return false
  }
}

export default quotationValidate
