import { gsap, Back, Sine } from "gsap";

const loadingAnim = () => {
  const loadingArea = document.querySelector('.loading');

  if(loadingArea !== null) {
    const images = document.getElementsByTagName('img');
    const loadingGauge = loadingArea.querySelector('.loading-text-wrap .overlay');
    const icon01 = loadingArea.querySelector('.icon-01');
    const icon02 = loadingArea.querySelector('.icon-02');
    let imgCounting = 0;
    let baseCounting = 0;
    const gaugeWidth = 100; // ゲージの全体幅
    let current;
  
    gsap
      .timeline({
        repeat: -1,
        repeatDelay: .5
      })
      .to(icon01, {
        scale: 1,
        ease: Back.easeOut,
        duration: .5
      })
      .to(icon01, {
        opacity: 1,
        ease: Sine.easeOut,
        duration: .5
      }, '<')
      .to(icon01, {
        opacity: 0,
        ease: Sine.easeOut,
        duration: .4
      }, '+=.2')
      .to(icon01, {
        scale: 0,
        duration: 0
      }, '0')
  
      gsap
      .timeline({
        repeat: -1,
        repeatDelay: .5,
        delay: .2,
      })
      .to(icon02, {
        scale: 1,
        ease: Back.easeOut,
        duration: .5
      })
      .to(icon02, {
        opacity: 1,
        ease: Sine.easeOut,
        duration: .5
      }, '<')
      .to(icon02, {
        opacity: 0,
        ease: Sine.easeOut,
        duration: .4
      }, '+=.2')
      .to(icon02, {
        scale: 0,
        duration: 0
      }, '0')
  
    // 画像の読み込み
    for (let i = 0; i < images.length; i++) {
      const img = new Image(); // 新たなimg要素を作成
      // 画像読み込み完了したとき
      img.onload = function() {
        imgCounting += 1;
      }
      // 画像読み込み失敗したとき
      img.onerror = function() {
        imgCounting += 1;
      }
      img.src = images[i].src; // ソースのパスを設定
    };
  
    // setIntervalを使って一定時間ごとに処理を繰り返す
    const nowLoading = setInterval(function () {
      if(baseCounting <= imgCounting) {
        current = Math.floor(baseCounting / images.length * 100);
        const rate = Math.floor(gaugeWidth / 100 * current) + '%';
        loadingGauge.style.clipPath = 'polygon(0 0, ' + rate + ' 0, ' + rate + ' 100%, 0 100%)';
        baseCounting += 1;
  
        // 全て読み込んだ時
        if (baseCounting === images.length) {
          setTimeout(function() {
            loadingArea.classList.add('is-loaded');
            clearInterval(nowLoading);
          }, 1900);
        }
      }
    }, 50);
  }
};

export default loadingAnim;
