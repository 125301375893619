const detailConfigCalc = () => {
  const detail = document.querySelector('.section-quotation-detail.config')

  if (detail) {
    const total = detail.querySelector('#total-coef')
    const totalInput = detail.querySelector('input[name="total-coef"]')
    const inputs = detail.querySelectorAll('.check-list input')

    const calc = () => {
      let value = [...inputs].reduce((prev, current) => {
        return current.checked ? prev + Number(current.value) - 1.0 : prev + 0
      }, 1.0)

      value = Math.round(value * 10) / 10
      value = value.toFixed(1)
      total.innerText = value
      totalInput.value = value
    }

    calc()

    inputs.forEach((input) => {
      input.addEventListener('change', () => calc())
    })
  }
}

export default detailConfigCalc
