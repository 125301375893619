import { z } from 'zod'
import {
  numberRegexSchema,
  numberSchema,
  requiredSchema,
} from './validationSchema'

export let toggleButton

const expensesValidation = () => {
  const target = document.querySelector('.section-quotation-detail.expenses')
  if (target) {
    const submitButton = document.querySelector('.js-submit-btn')

    const configs = target.querySelectorAll('.detail-config:not(.js-multi)')

    configs.forEach((config) => {
      const checkbox = config.querySelector('input[type="checkbox"]')
      const textInputs = config.querySelectorAll('input[type="text"]')
      const numberInputs = config.querySelectorAll('input[type="number"]')
      const select = config.querySelector('select')

      let flag = false

      const inputs = [...textInputs, ...numberInputs]

      if (!checkbox.checked) {
        inputs.forEach((input) => (input.disabled = true))
        if (select) select.disabled = true
      }

      checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
          inputs.forEach((input) => (input.disabled = false))
          if (select) select.disabled = false
          if (flag) {
            textInputs.forEach((input) => {
              if (input.id !== 'product-spec') {
                validation(input, requiredSchema)
              }
            })
            numberInputs.forEach((input) => validation(input, numberSchema))
          }
        } else {
          inputs.forEach((input) => (input.disabled = true))
          if (select) select.disabled = true
          inputs.forEach((input) => removeError(input))
        }
      })

      textInputs.forEach((input) => {
        if (input.id !== 'product-spec') {
          input.addEventListener('change', () => {
            if (!flag) flag = true
            validation(input, requiredSchema)
          })
        }
      })
      numberInputs.forEach((input) =>
        input.addEventListener('change', () => {
          if (!flag) flag = true
          validation(input, numberSchema)
        })
      )
    })

    // ボタンのアクティブ、非アクティブ
    let results = []
    const pushSuccess = (el, schema) => {
      const result = schema.safeParse(el.value).success
      results.push(result)
    }

    toggleButton = () => {
      results = []

      configs.forEach((config) => {
        const checkbox = config.querySelector('input[type="checkbox"]')

        if (checkbox.checked) {
          const textInputs = config.querySelectorAll('input[type="text"]')
          const numberInputs = config.querySelectorAll('input[type="number"]')

          textInputs.forEach((input) => {
            pushSuccess(input, requiredSchema)
          })
          numberInputs.forEach((input) => {
            pushSuccess(input, numberSchema)
          })
        }
      })

      const flag = results.length < 1 ? false : results.every((bool) => bool)

      if (flag) {
        submitButton.disabled = false
        submitButton.classList.remove('inactive')
      } else {
        submitButton.disabled = true
        submitButton.classList.add('inactive')
      }
    }

    toggleButton()

    document.querySelectorAll('input[type="text"]').forEach((el) => {
      el.addEventListener('change', () => toggleButton())
    })
  }
}

/**
 * エラーメッセージを挿入
 * @param {Element} el - 主にinputなど
 * @param {error.issues} issues
 */
const errorMessage = (el, issues) => {
  el.classList.add('is-invalid')
  issues.forEach((e) => {
    el.insertAdjacentHTML(
      'afterend',
      `<div class="invalid-feedback">${e.message}</div>`
    )
  })
}

/**
 * エラーメッセージを削除
 * @param {Element} el - 主にinputなど
 */
const removeError = (el) => {
  el.classList.remove('is-invalid')
  const parent = el.parentElement
  const errors = parent.querySelectorAll(':scope > .invalid-feedback')
  errors.forEach((error) => {
    error.remove()
  })
}

export const validation = (el, schema) => {
  removeError(el)

  try {
    schema.parse(el.value)
  } catch (error) {
    errorMessage(el, error.issues)
  }
}

export const addressSchema = z
  .string()
  .nonempty('必須項目です。')
  .max(255, '２５５文字以内で入力してください。')

export default expensesValidation
