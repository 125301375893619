const primaryButton = () => {
  const buttons = document.querySelectorAll(".js-effect-btn");

  if (buttons.length > 0) {
    buttons.forEach((button) => {
      const wrap = button.querySelector(".effect-wrap");
      const effect = button.querySelector(".effect");

      button.addEventListener("click", (e) => {
        const { offsetX, offsetY } = e;
        wrap.style.top = `${offsetY}px`;
        wrap.style.left = `${offsetX}px`;

        effect.style.transition = "none";
        effect.style.opacity = 0.3;
        effect.style.transform = "scale(0.2, 0.2)";

        window.setTimeout(() => {
          effect.style.transition = "";
          effect.style.opacity = 0;
          effect.style.transform = "scale(1, 1)";
        }, 10);
      });
    });
  }
};

export default primaryButton;
