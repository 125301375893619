const customerSwitch = () => {
  const parent = document.querySelector('.customer-add-switch')
  if (parent) {
    const radioButtons = parent.querySelectorAll('input[name="customer-type"]'),
      companyWrap = document.querySelector('.customer-add-info-company'),
      companyInput = document.querySelector('input[name="company"]'),
      nameTitle = document.querySelector(
        '.customer-add-info-name .customer-add-info__title'
      )

    const showCompany = () => {
      companyWrap.classList.add('is-show')
      companyInput.disabled = false
      nameTitle.innerText = 'ご担当者名'
    }
    const hideCompany = () => {
      companyWrap.classList.remove('is-show')
      companyInput.disabled = true
      nameTitle.innerText = 'お名前'
    }

    radioButtons.forEach((button) => {
      button.addEventListener('change', (e) => {
        if (button.checked) {
          switch (e.target.value) {
            case '個人':
              hideCompany()
              break
            case '法人':
              showCompany()
              break
          }
        }
      })

      if (button.checked) {
        switch (button.value) {
          case '個人':
            hideCompany()
            break
          case '法人':
            showCompany()
            break
        }
      }
    })
  }
}

export default customerSwitch
