const customerResultControll = () => {
  const sortBtn = document.querySelector('.js-sort-btn');
  const narrowBtn = document.querySelector('.js-narrow-btn');
  const narrowCloseBtn = document.querySelector('.js-narrow-close');

  function close(target) {
    target.classList.remove('is-active');
    target.nextElementSibling.classList.remove('is-open');
  }

  function setEvent(target) {
    if(target) {
      target.addEventListener('click', function() {

        if(sortBtn.classList.contains('is-active') && target == narrowBtn) {
          close(sortBtn);
        } else if(narrowBtn.classList.contains('is-active') && target == sortBtn) {
          close(narrowBtn);
        }
        this.classList.toggle('is-active');
        this.nextElementSibling.classList.toggle('is-open');
      })
    }

    if(narrowCloseBtn) {
      narrowCloseBtn.addEventListener('click', function() {
        close(narrowBtn);
      })
    }
  }

  setEvent(sortBtn);
  setEvent(narrowBtn);
}

export default customerResultControll;