import MicroModal from 'micromodal'

const quotationItemDeleteButton = () => {
  const items = document.querySelectorAll('.is-quotation .quotation__edit-item')

  if (items.length > 0) {
    const closeButton = document.querySelector('.js-modal-close'),
      modalDeleteButton = document.querySelector('.js-back-btn')

    closeButton.onclick = () => {
      const target = document.querySelector('.delete-form[data-delete]')
      target.removeAttribute('data-delete')
      MicroModal.close('confirm-modal')
    }

    modalDeleteButton.addEventListener('click', () => {
      const target = document.querySelector('.delete-form[data-delete]')
      target.submit()
      MicroModal.close('confirm-modal')
    })

    items.forEach((el) => {
      const button = el.querySelector('.quotation-edit__btn_delete')
      button.onclick = (e) => {
        e.preventDefault()
        MicroModal.show('confirm-modal')
        const target = button.closest('.delete-form')
        target.setAttribute('data-delete', '')
      }
    })
  }
}

export default quotationItemDeleteButton
