const globalNav = () => {
  const button = document.getElementById("hamburger-btn");
  const nav = document.getElementById("js-globalnav");

  if (button) {
    button.onclick = () => {
      button.classList.toggle("is-open");
      nav.classList.toggle("is-open");
    };
  }
};

export default globalNav;
